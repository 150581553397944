import { useEffect, useState } from "react";
import { authenticationParameters, msalInstance } from "./AuthZ";

let tokenResponse: any = {};
let isAuthenticated: boolean = false;

async function handleLogin(instance: any) {
  //After login
  await instance.initialize().catch((e: any) => {});
  //we will get token for first login
  tokenResponse = await instance.handleRedirectPromise().catch((e: any) => {
  });
  if (!tokenResponse) {
    //on rest of page refresh, looking for cached account
    const accounts = await instance.getAllAccounts();

    //if no cached account found then login again
    if (accounts.length === 0) {
      tokenResponse = await instance
        .loginRedirect(authenticationParameters)
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      //if there is cached account then user is authenticated
      isAuthenticated = true;
    }
  } else {
    //if there is token then user is authenticated
    isAuthenticated = true;
  }
}

export const AuthenticateUser = (props: any) => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  useEffect(() => {
    handleLogin(msalInstance).then(() => {
      const isExist = localStorage.getItem("userAlreadyLoggedIn");

      if (!isExist) {
        localStorage.setItem("userAlreadyLoggedIn", "false");
      }

      if (tokenResponse) {
        isAuthenticated = true;
        if (tokenResponse.account) {
          localStorage.setItem("UserEmailID", tokenResponse.account.username);
          if (tokenResponse.account.idTokenClaims) {
            localStorage.setItem(
              "WWID",
              tokenResponse.account.idTokenClaims.employeeId
            );
          }
        }
      }
      setUserAuthenticated(true);
    });
  }, []);

  return <>{isAuthenticated ? props.children : ""}</>;
};
