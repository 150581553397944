import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { getAllUserAdminAuditsAPI } from "../../config/constants";

interface UserAdminAuditState {
  UserAdminAudits: {
    AuditCode: string;
    AuditEndDate: string;
    AuditID: number;
    AuditSite: string;
    AuditStartDate: string;
    AuditStatus: string;
    AuditType: string;
    CompanyID: number;
    ServerDelta: number;
    isGuest: false;
    isHost: false;
    isSME: false;
    isSRC: false;
    isScribe: false;
    isTeamLeader: false;
    isTeamMember: false;
    isUserAdmin: false;
  };
}

// Define the initial state using that type
const initialState: UserAdminAuditState = {
  UserAdminAudits: {
    AuditCode: "",
    AuditEndDate: "",
    AuditID: 0,
    AuditSite: "",
    AuditStartDate: "",
    AuditStatus: "",
    AuditType: "",
    CompanyID: 0,
    ServerDelta: 0,
    isGuest: false,
    isHost: false,
    isSME: false,
    isSRC: false,
    isScribe: false,
    isTeamLeader: false,
    isTeamMember: false,
    isUserAdmin: false,
  },
};

export const getUserAdminAudits = createAsyncThunk(
  "audits/getUserAdminAudits",
  async (payload: any) => {
    try {
      const response = await fetch(getAllUserAdminAuditsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const UserAdminAuditSlice = createSlice({
  name: "UserAdminAudit",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UserAdminAuditState>) => {
    builder.addCase(getUserAdminAudits.pending, (state, action) => {
      return { ...state };
    });
    builder.addCase(getUserAdminAudits.fulfilled, (state, action) => {
      return {
        ...state,
        UserAdminAudits: action.payload?.data?.[0],
      };
    });
    builder.addCase(getUserAdminAudits.rejected, (state, action) => {
      return { ...state };
    });
  },
});

export default UserAdminAuditSlice.reducer;
