import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllAuditInfoAPI,
  getAllAuditsByCompanyAPI,
  getLoginAPI,
  getNewSessionAPI,
} from "../../config/constants";

interface headerState {
  isUserITAdmin: boolean;
  isCompanyChanged: boolean;
  configuration: { VersionNumber: string; HeaderDisplay: string };
  user: { WWID: string; EmailID: string; FirstName: string; LastName: string };
  companyList: any[];
  selectedCompanyId: any;
  auditList: any[];
  selectedAuditId: any;
  selectedAudit: {
    AuditID: number;
    AuditCode: string;
    AuditType: string;
    AuditSite: string;
    AuditStatus: string;
    AuditStartDate: string;
    AuditEndDate: string;
    isGuest: boolean;
    isSME: boolean;
    isScribe: boolean;
    isHost: boolean;
    isSRC: boolean;
    isTeamLeader: boolean;
    isTeamMember: boolean;
    isUserAdmin: boolean;
    ServerDelta: number;
  };
  isLoading: boolean;
  isTimerUp: boolean;
  lastUpdateTime: string;
  userLoginStatus: number;
  getAuditsByCompanyStatus: number;
  sessionId: string;
}

const initialState: headerState = {
  configuration: { VersionNumber: "", HeaderDisplay: "" },
  user: { WWID: "", EmailID: "", FirstName: "", LastName: "" },
  companyList: [],
  selectedCompanyId: 0,
  auditList: [],
  selectedAuditId: 0,
  isLoading: false,
  isUserITAdmin: false,
  isCompanyChanged: false,
  selectedAudit: {
    AuditID: 0,
    AuditCode: "",
    AuditType: "",
    AuditSite: "",
    AuditStatus: "",
    AuditStartDate: "",
    AuditEndDate: "",
    isGuest: false,
    isSME: false,
    isScribe: false,
    isHost: false,
    isSRC: false,
    isTeamLeader: false,
    isTeamMember: false,
    isUserAdmin: false,
    ServerDelta: 0,
  },
  isTimerUp: false,
  lastUpdateTime: "",
  userLoginStatus: 0,
  getAuditsByCompanyStatus: 0,
  sessionId: "",
};

export const getUserLogin = createAsyncThunk(
  "login/getUserLogin",
  async (payload: any) => {
    try {
      const response = await fetch(`${getLoginAPI}`, {
        method: "POST",
        mode: "cors",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payload,
        }),
      });
      const res = await response.json();
      return res;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAllAuditsByCompany = createAsyncThunk(
  "login/getAllAuditsByCompany",
  async (payload: any) => {
    try {
      const response = await fetch(getAllAuditsByCompanyAPI, {
        method: "POST",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const res = await response.json();
      return res;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAllAuditsByCompany1 = createAsyncThunk(
  "login/getAllAuditsByCompany1",
  async (CompanyID: any) => {
    try {
      const response = await fetch(`${getAllAuditInfoAPI}/${CompanyID}`, {
        //credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getNewSession = createAsyncThunk(
  "login/getNewSession",
  async () => {
    try {
      const response = await fetch(getNewSessionAPI, {
        //credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const headerSlice = createSlice({
  name: "headerData",
  initialState,
  reducers: {
    setLastUpdateTimer(state, action: PayloadAction<{ time: string }>) {
      state.lastUpdateTime = action.payload.time;
    },
    handleTimer(state, action: PayloadAction<{ isTimerUp: boolean }>) {
      state.isTimerUp = action.payload.isTimerUp;
    },
    create_update_audit(
      state,
      action: PayloadAction<{ auditList: any; selectedAudit: any }>
    ) {
      state.auditList = action.payload.auditList;
      state.selectedAudit = action.payload.selectedAudit;
    },
    handleSelectAuditChange(state, action: PayloadAction<{ audit: any }>) {
      state.selectedAudit = action.payload.audit;

      localStorage.setItem(
        "selectedAuditId",
        action?.payload?.audit?.AuditID || 0
      );
    },
    setCurrentSelecetedCompany(
      state,
      action: PayloadAction<{ currentSelectedCompany?: string }>
    ) {
      if (action.payload.currentSelectedCompany) {
        state.isCompanyChanged = true;
        state.selectedCompanyId = action.payload.currentSelectedCompany;
      } else {
        state.isCompanyChanged = true;
        state.selectedCompanyId = action.payload;
      }
      if (state.selectedCompanyId) {
        localStorage.setItem("currentCompanyId", state.selectedCompanyId);
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<headerState>) => {
    builder.addCase(getUserLogin.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getUserLogin.fulfilled, (state, action) => {
      const data = action.payload?.loginHeader;
      const status = action.payload?.status;
      if (data?.selectedCompany?.[0]?.CompanyID) {
        localStorage.setItem(
          "currentCompanyId",
          data?.selectedCompany?.[0]?.CompanyID
        );
      }
      if (data?.userSessionDetails?.SessionID)
        localStorage.setItem(
          "selectedAuditId",
          data?.selectedCompany?.[0]?.AuditID || 0
        );
      return {
        ...state,
        userLoginStatus: status,
        isLoading: false,
        configuration: data?.configuration?.[0] || state.configuration,
        user: data?.selectedCompany?.[0] || state.user,
        companyList: data?.selectAllCompany || [],
        selectedCompanyId: data?.selectedCompany?.[0]?.CompanyID || 0,
        auditList: data?.defaultcompanyAudit || [],
        selectedAuditId: data?.selectedCompany?.[0]?.AuditID || 0,
        isUserITAdmin: data?.isAdmincount?.length ? true : false,
        selectedAudit: data?.defaultcompanyAudit?.[0] || state.selectedAudit,
        sessionId: data?.userSessionDetails?.SessionID || "",
      };
    });
    builder.addCase(getUserLogin.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(getAllAuditsByCompany.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getAllAuditsByCompany.fulfilled, (state, action) => {
      const data = action.payload?.updatedUserSession;
      const status = action.payload?.status;
      const defaultAudit = {
        AuditID: 0,
        AuditCode: "",
        AuditType: "",
        AuditSite: "",
        AuditStartDate: "",
        AuditEndDate: "",
        AuditStatus: "",
        isGuest: false,
        isSME: false,
        isScribe: false,
        isHost: false,
        isSRC: false,
        isTeamLeader: false,
        isTeamMember: false,
        isUserAdmin: false,
      };
      localStorage.setItem(
        "selectedAuditId",
        data?.data?.defaultcompanyAudit?.[0]?.AuditID || 0
      );
      return {
        ...state,
        getAuditsByCompanyStatus: status,
        auditList: data?.data?.defaultcompanyAudit || [],
        selectedAudit: data?.data?.defaultcompanyAudit?.[0] || defaultAudit,
        isLoading: false,
      };
    });
    builder.addCase(getAllAuditsByCompany.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(getAllAuditsByCompany1.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getAllAuditsByCompany1.fulfilled, (state, action) => {
      localStorage.setItem(
        "selectedAuditId",
        action.payload?.data?.[0]?.AuditID || 0
      );
      const defaultAudit = {
        AuditID: 0,
        AuditCode: "",
        AuditType: "",
        AuditSite: "",
        AuditStartDate: "",
        AuditEndDate: "",
        AuditStatus: "",
        isGuest: false,
        isSME: false,
        isScribe: false,
        isHost: false,
        isSRC: false,
        isTeamLeader: false,
        isTeamMember: false,
        isUserAdmin: false,
      };
      return {
        ...state,
        auditList: action.payload?.data || [],
        selectedAudit: action.payload?.data?.[0] || defaultAudit,
        isLoading: false,
      };
    });
    builder.addCase(getAllAuditsByCompany1.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(getNewSession.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });
  },
});

export const {
  handleSelectAuditChange,
  setCurrentSelecetedCompany,
  handleTimer,
  setLastUpdateTimer,
  create_update_audit,
} = headerSlice.actions;
export default headerSlice.reducer;
