import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
const logger = createLogger({});

import loginReducer from "./slice/loginSlice";
import auditReducer from "./slice/auditSlice";
import adminReportReducer from "./slice/adminReportsSlice";
import userReportReducer from "./slice/userReportsSlice";
import companyReducer from "./slice/companySlice";
import auditStatusReducer from "./slice/auditStatusSlice";
import auditTypeReducer from "./slice/auditTypeSlice";
import auditSiteReducer from "./slice/auditSiteSlice";
import userRolesReducer from "./slice/userRolesSlice";
import tickerReducer from "./slice/tickerSlice";
import auditRequestReducer from "./slice/auditRequestSlice";
import headerDataReducer from "./slice/headerSlice";
import scribeReducer from "./slice/scribeSlice";
import docReducer from "./slice/docSlice";
import contactAdminReducer from "./slice/contactAdminSlice";
import userMaintenanceLDAPSyncReducer from "./slice/LDAPSyncSlice";
import colorThemeReducer from "./slice/colorThemeSlice";
import UserAdminAuditReducer from "./slice/UserAdminAuditSlice";

export const store = configureStore({
  reducer: {
    audit: auditReducer,
    login: loginReducer,
    adminReport: adminReportReducer,
    userReport: userReportReducer,
    company: companyReducer,
    auditStatus: auditStatusReducer,
    auditType: auditTypeReducer,
    auditSite: auditSiteReducer,
    userRoles: userRolesReducer,
    ticker: tickerReducer,
    auditRequest: auditRequestReducer,
    header: headerDataReducer,
    scribe: scribeReducer,
    doc: docReducer,
    contactAdmin: contactAdminReducer,
    ldapSync: userMaintenanceLDAPSyncReducer,
    colorTheme: colorThemeReducer,
    userAdminAudit: UserAdminAuditReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
